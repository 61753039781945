import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FirstPaymentStep } from '@app/core/entities/payment.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentRepository {
  paymentDetails = signal<FirstPaymentStep | null>(null);

  paymentDetails$ = toObservable(this.paymentDetails);

  setPaymentDetails(paymentDetails: FirstPaymentStep | null) {
    this.paymentDetails.set(paymentDetails);
  }
}
